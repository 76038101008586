import uniqid from 'uniqid'
import GitHubIcon from '@material-ui/icons/GitHub'
import LaunchIcon from '@material-ui/icons/Launch'
import './ProjectContainer.css'
import Star from '@material-ui/icons/Star';

const ProjectContainer = ({ project, onclick }) => (
  <div className='project'>

    {project.favorite && (
      <div className='favorite'>



        <div className='star'>
          <Star/>
          <span className='favorite_text' title='hello'></span>
        </div>
        
      </div>
    )}

    <h3>{project.name}</h3>

    <p className='project__description'>{project.description}</p>
    {project.stack && (
      <ul className='project__stack'>
        {project.stack.map((item) => (
          <li key={uniqid()} className='project__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}

    {project.sourceCode && (
      <a
        href={project.sourceCode}
        aria-label='source code'
        className='link link--icon'
      >
        <GitHubIcon />
      </a>
    )}

    {project.livePreview && (
      <a
        aria-label='live preview'
        className='link link--icon'
        onClick={ () => onclick(project) }>
        <LaunchIcon />
      </a>
    )}
  </div>
)

export default ProjectContainer
