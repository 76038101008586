import React from 'react'
import './App.css'
import Home from './Pages/Home'
import College from  './Pages/College'
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import { type } from '@testing-library/user-event/dist/type';
//import bc from'./images/Colleges/bostoncollegelogo.png'
//import bcColor from './images/Colleges/bostoncollegeColors.jpg'


import {bostonCollege, 
  bostonUniversity, 
  brandeis, 
  cwru,
  nd,
  purdue,
  rit,
  ucb,
  uci,
  ucla,
  ucsb,
  ucsd,
  umass,
  umich,
  uofr,
  uw
} 
from './images/Images'


function App() {

  return (
    <div>
      
<Router>
    
    <Routes>
      
        <Route exact path='/'  element={<Home/>} />
        <Route path='/bc' element={<College logo={bostonCollege['logo']} imageOne={bostonCollege['imageOne']} imageTwo={bostonCollege['imageTwo']} imageThree={bostonCollege['imageThree']} color_one={'maroon'} color_two={'gold'} school={'BC'}/>} />
        <Route path='/brandeis' element={<College logo={brandeis['logo']} imageOne={brandeis['imageOne']} imageTwo={brandeis['imageTwo']} imageThree={brandeis['imageThree']} color_one={'blue'} color_two={'white'} school={'Brandeis'}/>} />
        <Route path='/bu' element={<College logo={bostonUniversity['logo']} imageOne={bostonUniversity['imageOne']} imageTwo={bostonUniversity['imageTwo']} imageThree={bostonUniversity['imageThree']} color_one={'maroon'} color_two={'gold'} school={'BU'}/>} />
        <Route path='/cwru' element={<College logo={cwru['logo']} imageOne={cwru['imageOne']} imageTwo={cwru['imageTwo']} imageThree={cwru['imageThree']} color_one={'navy'} color_two={'white'} school={'CWRU'}/>} />
        <Route path='/nd' element={<College logo={nd['logo']} imageOne={nd['imageOne']} imageTwo={nd['imageTwo']} imageThree={nd['imageThree']} color_one={'navy'} color_two={'gold'} school={'Notre Dame'}/>} />
        <Route path='/purdue' element={<College logo={purdue['logo']} imageOne={purdue['imageOne']} imageTwo={purdue['imageTwo']} imageThree={purdue['imageThree']} color_one={'gold'} color_two={'black'} school={'Purdue'}/>} />
        <Route path='/rit' element={<College logo={rit['logo']} imageOne={rit['imageOne']} imageTwo={rit['imageTwo']} imageThree={rit['imageThree']} color_one={'orange'} color_two={'white'} school={'RIT'}/>} />
        <Route path='/ucb' element={<College logo={ucb['logo']} imageOne={ucb['imageOne']} imageTwo={ucb['imageTwo']} imageThree={ucb['imageThree']} color_one={'blue'} color_two={'yellow'} school={'UC Berkley'}/>} />
        <Route path='/uci' element={<College logo={uci['logo']} imageOne={uci['imageOne']} imageTwo={uci['imageTwo']} imageThree={uci['imageThree']} color_one={'blue'} color_two={'yellow'} school={'UC Irvine'}/>} />
        <Route path='/ucla' element={<College logo={ucla['logo']} imageOne={ucla['imageOne']} imageTwo={ucla['imageTwo']} imageThree={ucla['imageThree']} color_one={'blue'} color_two={'yellow'} school={'UCLA'}/>} />
        <Route path='/ucsb' element={<College logo={ucsb['logo']} imageOne={ucsb['imageOne']} imageTwo={ucsb['imageTwo']} imageThree={ucsb['imageThree']} color_one={'blue'} color_two={'yellow'} school={'UCSB'}/>} />
        <Route path='/ucsd' element={<College logo={ucsd['logo']} imageOne={ucsd['imageOne']} imageTwo={ucsd['imageTwo']} imageThree={ucsd['imageThree']} color_one={'blue'} color_two={'yellow'} school={'UCSD'}/>} />
        <Route path='/umass' element={<College logo={umass['logo']} imageOne={umass['imageOne']} imageTwo={umass['imageTwo']} imageThree={umass['imageThree']} color_one={'red'} color_two={'white'} school={'Umass Amherst'}/>} />
        <Route path='/umich' element={<College logo={umich['logo']} imageOne={umich['imageOne']} imageTwo={umich['imageTwo']} imageThree={umich['imageThree']} color_one={'navy'} color_two={'yellow'} school={'University of Michigan'}/>} />
        <Route path='/ur' element={<College logo={uofr['logo']} imageOne={uofr['imageOne']} imageTwo={uofr['imageTwo']} imageThree={uofr['imageThree']} color_one={'blue'} color_two={'yellow'} school={'University of Rochester'}/>} />
        <Route path='/uw' element={<College logo={uw['logo']} imageOne={uw['imageOne']} imageTwo={uw['imageTwo']} imageThree={uw['imageThree']} color_one={'purple'} color_two={'white'} school={'University of Washington'}/>} />


    </Routes>
    </Router>
      </div>
  )
}

export default App