import React from 'react'
import B from '../components/Three/B'
import './College.css'
import { Route, Routes, useNavigate, Link } from 'react-router-dom';

function College(props) {
  return (
    <div>


           {/*<div className='headerr'>
            <h1 className='text'>Welcome {props.school}</h1>
            </div>
  */}
        <div className='spinLogo'>
            
            
        <Link to={'/'}>
            <div className='logo'>{ <B logo={props.logo} imageOne={props.imageOne} imageTwo={props.imageTwo} imageThree={props.imageThree}  school={props.school} color_one={props.color_one} color_two={props.color_two}  />}</div>
            </Link>

            
        </div>

        
    </div>
  )
}

export default College