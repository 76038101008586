import React from 'react'
import './ProjectModal.css';

import  CloseOutlined from '@material-ui/icons/CloseOutlined';
import ExitToAppRounded from '@material-ui/icons/ExitToAppRounded';
import CloseRounded from '@material-ui/icons/CloseRounded'
import Cancel from '@material-ui/icons/CancelOutlined'

function ProjectModal({currentPop, exit}) {
  return (
    <div  onClick={()=> exit(false)} className='ProjectModal'>
        
        <h6 className=''>{currentPop.name}</h6>
        <div className='closeIcon'>
        <Cancel className='Cancel'/>
        </div>



        <div className='body'>
        


        <div className='liveVideo'>
            {/*<h6>Live video</h6>*/}

            <video controls autoPlay src={currentPop.image} ></video>
              {/*<source src={} type="video/mp4"/>*/}
 
                

          </div>



        <div className='description'>
        <h6>Description</h6>  
        <p>{currentPop.description}</p>
      </div>



          

      
         
        </div>
        {/* <img src={currentPop.image} className={'liveVideo'}/> */}

       

    </div>
  )
}

export default ProjectModal