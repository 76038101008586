import { header } from '../../portfolio'
import Navbar from '../Navbar/Navbar'
import './Header.css'

const Header = (props) => {
  const { homepage, title } = header

  return (
    <header className='header center'>

      <h3 onClick={()=> props.onclick('Main')} style={{cursor:'pointer'}}>
        {title}
      </h3>
      <Navbar />
    </header>
  )
}

export default Header
