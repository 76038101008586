import { useContext,useState, useEffect } from 'react'
import { ThemeContext } from '../contexts/theme'
import Header from '../components/Header/Header'
import About from '../components/About/About'
import Projects from '../components/Projects/Projects'
import Skills from '../components/Skills/Skills'
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'

import FaceIcon from '../components/FaceIcon/FaceIcon'
import { projects } from '../portfolio'


import ProjectModal from '../ProjectModal/ProjectModal'

import Resume from '../components/Resume/Resume'



const Home = () => {
  const [{ themeName }] = useContext(ThemeContext)
  //const [screen, setScreen] = useState('Home')
  const [projectpop, setProjectPop] = useState(false);
  const [page, setPage] = useState('Main');
 
  

  return (
    <div id='top' className={`${themeName} app`}>
      <Header onclick={setPage} />
      

      
      <main>

      {page == 'Resume' &&
      
      <Resume onClick={setPage}/>
     }


      {page == 'Main' && 
      <div>

      
        <div className={projectpop === false ? 'none' : 'blurred'}>
          <About onClick={setPage}/> 
          <FaceIcon/> 
          <Skills /> 
          <Projects projectList={projects} onclick={setProjectPop}/>

        </div>
       <div>
        {projectpop === false ? <div></div> : <ProjectModal currentPop={projectpop} exit={setProjectPop}/>}
       </div>
        
        

        <div className={projectpop === false ? 'none' : 'blurred'}>
          <Contact />
          <footer className='footer'></footer>
  
        </div>
        </div>
}
      </main>


      <ScrollToTop />


    </div>
      
  )
}

export default Home
