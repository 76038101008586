import React from 'react';
import './Resume.css';
import resume from '../../images/ResumeNew.png'

import KeyboardReturn from '@material-ui/icons/KeyboardReturn'
function Resume(props) {
  return (
    <div className='resume'>
        <img src={resume} className='resumeImage'></img>

        
        {/*<KeyboardReturn onClick={()=> props.onClick('Main')}/>*/}
        
    </div>
  )
}

export default Resume