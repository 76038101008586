const bostonCollege = {
    logo: require('./Colleges/bc/bostoncollegelogo.png').default,
    imageOne: require('./Colleges/bc/BC1.jpg').default,
    imageTwo: require('./Colleges/bc/bostoncollegeOne.jpg').default,
    imageThree: require('./Colleges/bc/church.jpg').default
}//done

const bostonUniversity = {
    logo: require('./Colleges/bu/bulogo.gif').default,
    imageOne: require('./Colleges/bu/data.jpg').default,
    imageTwo: require('./Colleges/bu/community.png').default,
    imageThree: require('./Colleges/bu/research.jpg').default
}//done

const brandeis = {
    logo: require('./Colleges/brandeis/brandeisLogo.jpg').default,
    imageOne: require('./Colleges/brandeis/community.jpg').default,
    imageTwo: require('./Colleges/brandeis/cs.jpg').default,
    imageThree: require('./Colleges/brandeis/research.jpg').default
}//done

const cwru = {
    logo: require('./Colleges/cwru/cwruLogo.jpg').default,
    imageOne: require('./Colleges/cwru/cs.jpg').default,
    imageTwo: require('./Colleges/cwru/research.jpg').default,
    imageThree: require('./Colleges/cwru/community.jpg').default
}//done

const nd = {
    logo: require('./Colleges/nd/ndLogo.jpg').default,
    imageOne: require('./Colleges/nd/churchcc.jpg').default,
    imageTwo: require('./Colleges/nd/community.jpeg').default,
    imageThree: require('./Colleges/nd/cs.jpg').default
}//done

const purdue = {
    logo: require('./Colleges/purdue/purdueLogo.jpg').default,
    imageOne: require('./Colleges/purdue/ai.jpg').default,
    imageTwo: require('./Colleges/purdue/research.jpg').default,
    imageThree: require('./Colleges/purdue/community.jpg').default
}//done

const rit = {
    logo: require('./Colleges/rit/ritLogo.jpg').default,
    imageOne: require('./Colleges/rit/cs.jpg').default,
    imageTwo: require('./Colleges/rit/community.jpg').default,
    imageThree: require('./Colleges/rit/research.jpg').default
}//done

const ucb = {
    logo: require('./Colleges/ucb/ucbLogo.png').default,
    imageOne: require('./Colleges/ucb/cs.jpeg').default,
    imageTwo: require('./Colleges/ucb/research.jpg').default,
    imageThree: require('./Colleges/ucb/community.jpeg').default
}//done

const uci = {
    logo: require('./Colleges/uci/uciLogo.gif').default,
    imageOne: require('./Colleges/uci/cs.jpg').default,
    imageTwo: require('./Colleges/uci/research.jpg').default,
    imageThree: require('./Colleges/uci/community.jpg').default
}

const ucla = {
    logo: require('./Colleges/ucla/uclaLogo.png').default,
    imageOne: require('./Colleges/ucla/cs.jpeg').default,
    imageTwo: require('./Colleges/ucla/research.jpg').default,
    imageThree: require('./Colleges/ucla/community.jpg').default
}

const ucsb = {
    logo: require('./Colleges/ucsb/ucsbLogo.gif').default,
    imageOne: require('./Colleges/ucsb/community.jpg').default,
    imageTwo: require('./Colleges/ucsb/cs.jpg').default,
    imageThree: require('./Colleges/ucsb/research.jpg').default
}

const ucsd = {
    logo: require('./Colleges/ucsd/ucsdLogo.png').default,
    imageOne: require('./Colleges/ucsd/community.jpg').default,
    imageTwo: require('./Colleges/ucsd/cs.jpg').default,
    imageThree: require('./Colleges/ucsd/research.jpg').default
}

const umass = {
    logo: require('./Colleges/umass/umassLogo.webp').default,
    imageOne: require('./Colleges/umass/community.jpg').default,
    imageTwo: require('./Colleges/umass/research.jpg').default,
    imageThree: require('./Colleges/umass/cs.jpg').default
}

const umich = {
    logo: require('./Colleges/umich/umichLogo.png').default,
    imageOne: require('./Colleges/umich/cs.jpg').default,
    imageTwo: require('./Colleges/umich/philosophy.jpg').default,
    imageThree: require('./Colleges/umich/r.jpg').default
}

const uofr = {
    logo: require('./Colleges/uofr/uofrLogo.jpg').default,
    imageOne: require('./Colleges/uofr/community.webp').default,
    imageTwo: require('./Colleges/uofr/cs.jpg').default,
    imageThree: require('./Colleges/uofr/research.jpg').default
}

const uw = {
    logo: require('./Colleges/UW/uwLogo.jpg').default,
    imageOne: require('./Colleges/UW/css.jpg').default,
    imageTwo: require('./Colleges/UW/community.jpg').default,
    imageThree: require('./Colleges/UW/research.jpg').default
}

export { bostonCollege,
    bostonUniversity, 
    brandeis, 
    cwru,
    nd,
    purdue,
    rit,
    ucb,
    uci,
    ucla,
    ucsb,
    ucsd,
    umass,
    umich,
    uofr,
    uw
    
}
