import { render } from 'react-dom'
import App from './App'
import { ThemeProvider } from './contexts/theme'
import './index.css'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


{
  /*
const router = createBrowserRouter([
  {
    path: "/JohnnyFoFo.github.io",
    element: <div><App/></div>,
  },
]);
*/
}
//https://reactrouter.com/en/main/start/tutorial
render(
  <ThemeProvider>

  <App/>
  </ThemeProvider>,
  document.getElementById('root')
)
