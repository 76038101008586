import stats from './images/stats.png'
import statsVideo from './images/Stats.mov'
import idp from './images/IDP.mov'
import physics from './images/physics.MOV'
import movie from './images/physicsSim.mp4'
import evenorodd from './images/evenorodd.MOV'
import pw from './images/pw.mp4'
import snakeVideo from './images/snake.mp4'
import faceTracker from './images/FaceTracker.mp4'
import graphiccolumns from './images/columnsvisual.mp4'
import columns from './images/colums.mp4'
import weather from './images/WeatherAPI.mp4'
import connectfour from './images/connectfour.mp4'
import collegePage from './images/colleges.mp4'

const header = {

  homepage: '/',
  title: 'JAF',
}

const about = {

  name: 'John Fortin',
  role: 'Software Developer',
  description:
    'A UC Irvine Freshman Data Science Major from Massachusetts who enjoys Web Development, Machine Learning, and Math.',
  resume: 'https://example.com',
  social: {
    linkedin: 'https://www.linkedin.com/in/john-fortin-742824233/',
    github: 'https://github.com/JohnnyFoFo',
  },
}

const projects = [
  {
    name: 'Snake Reinforcement Learning Project + Game',
    description: 'Used Pygame Library and Pytorch to create a Snake Game that is playable to a user, as well as a model which can be observed in real-time by the user',
    stack: ['python','pytorch','numpy','pygame','matplotlib'],
    sourceCode: 'https://github.com/JohnnyFoFo/Snake-Reinforcement-Learning-Game/tree/master',
    livePreview: true,
    image: snakeVideo,
    favorite: true
  },
  {
    name: 'Facial Recognition Project',
    description: 'I wrote a program which uses a computer\'s camera and identifies a bounding box of where any face/s are',
    stack: ['python','keras','numpy','tensorflow','matplotlib','albumentations'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
    image: faceTracker,
    favorite: true
  },
  {
    name: 'Personal Website',
    description: 
    'A personal website which Hosts my projects',
    stack: ['Javascript', 'Html', 'React', 'Css'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
    image: pw,
    favorite: true

  },
  {
    name: 'College Admission Landing Pages',
    description: 
    'Star-Wars-styled landing page I made for specific Universities I applied to',
    stack: ['Javascript', 'Html', 'React', 'Css', 'ThreeJs'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
    image: collegePage,
    favorite: true

  },
  {
    name: 'AP Statistics Calculator',
    description:
      'I made an AP Statistics Calculator with a usable and clean user interface that implements the simple statistics javascript library.',
    stack: ['Javascript', 'Html', 'React', 'Css'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
    image: statsVideo
  },
  {
    name: 'Identifying Digits  Project',
    description:
      'Made a neural network that classifies a handwritten digit as a number 0-9',
    stack: ['HTML', 'Python', 'TensorFlow','Keras'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
    image: idp
  },
  {
    name: 'Physics Simulator',
    description:
      'Does a free-fall simulation using earth physics',
    stack: ['HTML', 'css', 'Javascript','React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
    image: movie
  },
  {
    name: 'Fully-Custom Nerf Gun',
    description: 'My final project for AP Physics 1/  Used a plunger based design which utilized air/  was my first time...',
    stack: ['physics'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
    image: physics
  },
  {
    name: 'Even or Odd Neural Network',
    description: 'I decided to over-engineer the most basic of questions: Even or Odd?',
    stack: ['python','keras','numpy'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
    image: evenorodd
  },
  {
    name: 'ESP Object Detection',
    description: 'A program in which active camera footage from an ESP camera gets converted into images, and those images are classified as containing certain objects',
    stack: ['python','keras','numpy', 'c++'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
    image: evenorodd
  },
  {
    name: 'Visual Columns Game',
    description: 'Used Pygame Library and Pytorch to create a Snake Game that is playable to a user, as well as a model which can be observed in real-time by the user',
    stack: ['python','pygame'],
    livePreview: 'https://github.com/JohnnyFoFo/Snake-Reinforcement-Learning-Game/tree/master',
    image: graphiccolumns
  },
  {
    name: 'Printed Columns Game',
    description: 'Used Pygame Library and Pytorch to create a Snake Game that is playable to a user, as well as a model which can be observed in real-time by the user',
    stack: ['python'],
    livePreview: 'https://github.com/JohnnyFoFo/Snake-Reinforcement-Learning-Game/tree/master',
    image: columns
  },
  {
    name: 'Weather API & File Tool',
    description: 'Used Pygame Library and Pytorch to create a Snake Game that is playable to a user, as well as a model which can be observed in real-time by the user',
    stack: ['python'],
    livePreview: 'https://github.com/JohnnyFoFo/Snake-Reinforcement-Learning-Game/tree/master',
    image: weather
  },
  {
    name: 'Connect Four Local and AI',
    description: 'Used Pygame Library and Pytorch to create a Snake Game that is playable to a user, as well as a model which can be observed in real-time by the user',
    stack: ['python'],
    livePreview: 'https://github.com/JohnnyFoFo/Snake-Reinforcement-Learning-Game/tree/master',
    image: connectfour
  }
]

const skills = [

  'HTML',
  'CSS',
  'JavaScript',
  'React',
  'Python',
  'java',
  'Material UI',
  'Git',
  'Keras',
  'Pandas',
  'Numpy',
  'Tensorflow',
  'Sci-kit-learn',
  
]

const contact = {

  email: 'jafortin@uci.edu',
}

export { header, about, projects, skills, contact }
