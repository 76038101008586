import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as THREE from "three";
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import space from '../../images/space.jpg'
import wim from '../../images/WelcomeImage.jpg'
//import bc_1 from '../../images/Colleges/BC1.jpg'
import love from '../../images/Lovee.jpg';
import {Text} from 'troika-three-text'
import exit from '../../images/exit.jpg'
import starwars from '../../images/Starjedi.ttf'

import moontexture from '../../images/moon.jpg'

class B extends Component {
  componentDidMount() {
    var scene = new THREE.Scene();
    
    var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
    
    window.addEventListener('resize', () => _onWindowResize(), false);
    function _onWindowResize(){
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    } 


    var renderer = new THREE.WebGLRenderer({antialias: true});
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize( window.innerWidth , window.innerHeight);
  
    // document.body.appendChild( renderer.domElement );
    // use ref as a mount point of the Three.js scene instead of the document.body
    this.mount.appendChild( renderer.domElement );
    var geometry = new THREE.BoxGeometry( 50, 50, 50 );

    const texture = new THREE.TextureLoader().load(this.props.logo);
    var material = new THREE.MeshBasicMaterial( { map: texture } );
    var cube = new THREE.Mesh( geometry, material );
    cube.position.set(0,0,-100)
    scene.add( cube );
    camera.position.z = 2300.3;



    //cube.addEventListener("click", ()=>location.assign('/'))
    const pointLight = new THREE.PointLight('white')
    pointLight.position.set(20,20,20)
    scene.add(pointLight)

    //const background = new THREE.TextureLoader().load(space)
    const background = new THREE.Color('black')

    scene.background = background

    const controls = new OrbitControls(camera, renderer.domElement)

    

    
    controls.noPan = true;
    controls.maxDistance = 10000 
    controls.noKeys = true;
    controls.noRotate = true;
    controls.noZoom = true;
{/*
    const shape = new THREE.Shape();
    shape.moveTo( 0,0 );
    shape.lineTo( 0, 10 );
    shape.lineTo( 50, 10 );
    shape.lineTo( 50, 0 );
    shape.lineTo( 0, 0 );
    
    const extrudeSettings = {
      steps: 1,
      depth: -10,
      bevelEnabled: true,
      bevelThickness: .1,
      bevelSize: .1,
      bevelOffset: 0,
      bevelSegments: 1
    };


const Buttongeometry = new THREE.ExtrudeGeometry( shape, extrudeSettings );
const Buttonmaterial = new THREE.MeshBasicMaterial( { color: 808080 } );
const Button = new THREE.Mesh( Buttongeometry, Buttonmaterial ) ;
Button.position.set(-25,-70,-70)
scene.add( Button );

  */}
function addStar(){
  const geometry = new THREE.SphereGeometry(.25,24,24)
  const material = new THREE.MeshStandardMaterial({color: 'white'})
  const star = new THREE.Mesh(geometry, material)
  const [x,y] = Array(2).fill().map(()=> THREE.MathUtils.randFloatSpread( 350))
  const z = THREE.MathUtils.randFloatSpread(2500)
  star.position.set(x,y,z)
  scene.add(star)
}

for (let i = 0; i< 500; i++){
  addStar()
}


/*
    const signShape = new THREE.Shape();
    signShape.moveTo( 0,0 );
    signShape.lineTo( 0, 10 );
    signShape.lineTo( 10, 10 );
    signShape.lineTo( 10, 0 );
    signShape.lineTo( 0, 0 );
    


    const signExtrudeSettings = {
      steps: 1,
      depth: -10,
      bevelEnabled: true,
      bevelThickness: .1,
      bevelSize: .1,
      bevelOffset: 0,
      bevelSegments: 1
    };
*/
    
  











    const ImageOneTexture =  new THREE.TextureLoader().load(this.props.imageOne);
    const ImageOnegeometry = new THREE.BoxGeometry( 70, 50, 1 );
    const ImageOnematerial = new THREE.MeshBasicMaterial( {map : ImageOneTexture} );
    const ImageOnecube = new THREE.Mesh( ImageOnegeometry, ImageOnematerial );
    ImageOnecube.position.set(-90,0,300)
    scene.add( ImageOnecube );

    
    const ImageThreeTexture =  new THREE.TextureLoader().load(this.props.imageTwo);
    const ImageThreegeometry = new THREE.BoxGeometry( 50, 50, 1 );
    const ImageThreematerial = new THREE.MeshBasicMaterial( {map : ImageThreeTexture} );
    const ImageThreecube = new THREE.Mesh( ImageThreegeometry, ImageThreematerial );
    ImageThreecube.position.set(90,0,600)
    scene.add( ImageThreecube );


    const ImageFourTexture =  new THREE.TextureLoader().load(this.props.imageThree);
    const ImageFourgeometry = new THREE.BoxGeometry( 50, 50, 1 );
    const ImageFourmaterial = new THREE.MeshBasicMaterial( {map : ImageFourTexture} );
    const ImageFourcube = new THREE.Mesh( ImageFourgeometry, ImageFourmaterial );
    ImageFourcube.position.set(-90,0,900)
    scene.add( ImageFourcube );

    const ImageTwoTexture =  new THREE.TextureLoader().load(love);
    const ImageTwogeometry = new THREE.BoxGeometry( 50, 50, 1 );
    const ImageTwomaterial = new THREE.MeshBasicMaterial( {map : ImageTwoTexture} );
    const ImageTwocube = new THREE.Mesh( ImageTwogeometry, ImageTwomaterial );
    ImageTwocube.position.set(90,0,1200)
    scene.add( ImageTwocube );

{/*
    const ExitTexture =  new THREE.TextureLoader().load(exit);
    const Exitgeometry = new THREE.BoxGeometry( 50, 50, 1 );
    const Exitmaterial = new THREE.MeshBasicMaterial( {map : ExitTexture} );
    const Exitcube = new THREE.Mesh( Exitgeometry, Exitmaterial );
    Exitcube.position.set(90,0,0)
    scene.add( Exitcube );

*/}
    const Ringgeometry = new THREE.TorusGeometry( 250, 10, 48, 100 );
    const Ringmaterial = new THREE.MeshBasicMaterial( { color: this.props.color_two } );
    const Ringtorus = new THREE.Mesh( Ringgeometry, Ringmaterial );
    Ringtorus.position.set(-450,0,500)
    scene.add( Ringtorus );

    const planetgeometry = new THREE.SphereGeometry( 100, 128, 128 );
    const planetmaterial = new THREE.MeshBasicMaterial( { color: this.props.color_one } );
    const planetsphere = new THREE.Mesh( planetgeometry, planetmaterial );
    planetsphere.position.set(-450,0,500)
    scene.add( planetsphere );

    const MOONtexture = new THREE.TextureLoader().load(moontexture)
    const MOONgeometry = new THREE.SphereGeometry( 100, 128, 128 );
    const MOONmaterial = new THREE.MeshBasicMaterial( {map: MOONtexture} );
    const MOONsphere = new THREE.Mesh( MOONgeometry, MOONmaterial );
    MOONsphere.position.set(500, 100,0)
    scene.add( MOONsphere );






{/*
    const welcomegeometry = new THREE.BoxGeometry( 1000, 100, 1 );
    const welcomematerial = new THREE.MeshBasicMaterial( {color: 'brown'} );
    const welcomecube = new THREE.Mesh( welcomegeometry, welcomematerial );
    welcomecube.position.set(0,0,1900)
    scene.add( welcomecube );

  */}

    const myText = new Text()
    scene.add(myText)
    
    // Set properties to configure:
    myText.text = ` Welcome ${this.props.school.toLowerCase()} Admissions. sit back and enjoy your landing page`
    myText.fontSize = 12
    myText.position.z = 1600
    myText.color = 'yellow'
    myText.position.x = -200 - (.2*myText.text.length)
    myText.position.y = 100
    myText.font = starwars
    // Update the rendering:
    myText.sync()




    const AcceptText = new Text()
    scene.add(AcceptText)
    
    // Set properties to configure:
    AcceptText.text = `i know your time is valuable thank you for your time :)`
    AcceptText.fontSize = 12
    AcceptText.position.z = 1400
    AcceptText.position.y = -100  
    AcceptText.color = 'yellow'
    AcceptText.position.x = -200
    AcceptText.font = starwars
    // Update the rendering:
    AcceptText.sync()


    const exit = new Text()
    scene.add(exit)
    
    // Set properties to configure:
    exit.text = `Click anywhere to enter portfolio!`
    exit.fontSize = 12
    exit.position.z = -100
    exit.position.y = 50
    exit.position.x = -100 
    exit.color = 'yellow'
    exit.font = starwars
    
    // Update the rendering:
    exit.sync()

{/*
  const ArrowShape = new THREE.Shape();
  ArrowShape.moveTo( 0,0 );
  ArrowShape.lineTo( 20, 0 );
  ArrowShape.lineTo( 20, 10 );
  ArrowShape.lineTo( 25, -2.5 );
  ArrowShape.lineTo( 20, -15);
  ArrowShape.lineTo( 20, -5);
  ArrowShape.lineTo( 0, -5);
  ArrowShape.lineTo( 0, 0);

  const ArrowextrudeSettings = {
    steps: 1,
    depth: -1,
    bevelEnabled: true,
    bevelThickness: .1,
    bevelSize: .1,
    bevelOffset: 0,
    bevelSegments: 1
  };
  const Arrowgeometry = new THREE.ExtrudeGeometry( ArrowShape, ArrowextrudeSettings );
  const Arrowmaterial = new THREE.MeshBasicMaterial( { color: this.props.color_one } );
  const Arrowmesh = new THREE.Mesh( Arrowgeometry, Arrowmaterial ) ;
  Arrowmesh.position.setZ(0)
  Arrowmesh.position.setX(-100)
  Arrowmesh.rotation.y +=6.5
  scene.add( Arrowmesh );
*/}





    ImageOnecube.rotation.y += .75
    ImageTwocube.rotation.y += -.75
    ImageThreecube.rotation.y += -.75
    ImageFourcube.rotation.y += .75


    var animate = function (timeTakenToRender) {
      requestAnimationFrame( animate );
      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;
      Ringtorus.rotation.x +=.001
      Ringtorus.rotation.y +=.001

      MOONsphere.rotation.x += .001
      MOONsphere.rotation.y += .001
      if(camera.position.z > 50){
        camera.position.z = camera.position.z -.0005*(timeTakenToRender)
      }
      
      //Arrowmesh.rotation.y +=.001
      //Button.rotation.y += 0.01;
      //WelcomeButton.rotation.x +=.01
      renderer.render( scene, camera );
      controls.update()
    };
    animate(0);
  }
  render() {
    return (
      <div ref={ref => (this.mount = ref)} />
    )
  }
}
export default B;