import React from 'react'
import './FaceIcon.css'
import pfp from '../../images/WebsitePhoto.png'

export default function FaceIcon() {
  return (
    <div className='FaceIcon'>
      <div className='circle'>
        <img className='Face' src={ pfp } alt='John Fortin'/>
      </div>
    </div>
  )
}

