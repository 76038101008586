import uniqid from 'uniqid'

import ProjectContainer from '../ProjectContainer/ProjectContainer'
import './Projects.css'

const Projects = ({ onclick, projectList }) => {
  if (!projectList.length) return null

  return (
    <section id='projects' className='section projects'>
      <div className='title_container'>

      <h2 className='section__title' >Projects</h2>

      </div>
      

      <div className='projects__grid'>
        {projectList.map((project) => (
          <ProjectContainer key={uniqid()} project={project} onclick={onclick} />
        ))}
      </div>
      
    </section>
  )
}

export default Projects
